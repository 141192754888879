import { createSlice } from "@reduxjs/toolkit";
import { Types, asyncActions } from "./duck";

const initialState: Types.AppState = {
  loading: false,
  language: "en",
  loggedInUser: null,
};

export const clients = createSlice({
  name: "appState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    [
      asyncActions.authenticateUser.fulfilled,
      asyncActions.login.fulfilled,
    ].forEach((pendingAction) => {
      builder.addCase(pendingAction, (state, { payload }) => {
        state.loggedInUser = payload;
        state.loading = false;
      });
    });

    [
      asyncActions.login.pending,
      asyncActions.logOut.pending,
      asyncActions.authenticateUser.pending,
    ].forEach((pendingAction) => {
      builder.addCase(pendingAction, (state) => {
        state.loading = true;
      });
    });
    [
      asyncActions.login.rejected,
      asyncActions.logOut.rejected,
      asyncActions.authenticateUser.rejected,
    ].forEach((pendingAction) => {
      builder.addCase(pendingAction, (state) => {
        state.loading = false;
      });
    });

    builder.addCase(asyncActions.logOut.fulfilled, (state) => {
      state.loading = false;
      state.loggedInUser = null;
    });
  },
});

export const { reducer, actions } = clients;
