import * as React from "react";
import { useSelector, useLocation } from "hooks";
import * as C from "components";
import { NotFound } from "./components";
import { constants, operations } from "./duck";
import { createUseStyles } from "react-jss";
import moment from "moment";
import { Navigate } from "react-router-dom";

const useStyles = createUseStyles({
  logo: {
    width: "100%",
    marginBottom: 30,
  },
  fullHeight: { height: "100%" },
  sider: {
    height: "100%",
    paddingTop: 24,
  },
});

const Layout = () => {
  const classes = useStyles();
  const [menuState, setMenuState] = React.useState({
    selectedKeys: ["dashboard"],
    collapsed: false,
  });
  const { pathname, search: locationSearch } = useLocation();
  const isAuthenticated = useSelector((state) => !!state.appState.loggedInUser);

  React.useEffect(() => {
    const selectedKeys = pathname.match(/\/\w+(\w|-)+/);
    setMenuState({
      ...menuState,
      selectedKeys: selectedKeys ? [selectedKeys[0].replace("/", "")] : [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const redirect = operations.queryString("redirect");

  return (
    <C.Layout className={classes.fullHeight} hasSider={isAuthenticated}>
      {isAuthenticated && (
        <C.Layout.Sider
          className={classes.sider}
          collapsible
          collapsed={menuState.collapsed}
          theme="light"
          onCollapse={() =>
            setMenuState({
              ...menuState,
              collapsed: !menuState.collapsed,
            })
          }
        >
          <C.Logo className={classes.logo} />
          <C.Menu
            theme="light"
            mode="inline"
            selectedKeys={menuState.selectedKeys}
            style={{ height: "100%" }}
          >
            <C.Menu.Item key="dashboard">
              <C.NavLink to="/dashboard">
                <C.DashboardOutlined />
                <span>Dashboard</span>
              </C.NavLink>
            </C.Menu.Item>
          </C.Menu>
        </C.Layout.Sider>
      )}
      <C.Layout>
        <C.Header />
        <C.Layout.Content
          style={{
            padding: 0,
            background: "#fff",
            minHeight: 360,
            overflowY: "auto",
          }}
        >
          <C.Routes>
            <>
              {constants.OPEN_ROUTES.map(({ path, Element }) => (
                <C.Route path={path} element={<Element />} />
              ))}
              {constants.SECURED_ROUTES.map(({ path, Element }, key) => {
                return (
                  <C.Route
                    // eslint-disable-next-line
                    key={key}
                    path={path}
                    element={
                      isAuthenticated ? (
                        <Element isAuthenticated={isAuthenticated} />
                      ) : (
                        <Navigate
                          to={`/login?redirect=${pathname}${locationSearch}`}
                        />
                      )
                    }
                  />
                );
              })}
              <C.Route
                path="/"
                element={
                  <C.Navigate
                    to={isAuthenticated ? redirect || "/dashboard" : "/login"}
                  />
                }
              />
              <C.Route path="*" element={<NotFound />} />
            </>
          </C.Routes>
        </C.Layout.Content>
        <C.Layout.Footer style={{ textAlign: "center" }}>
          <strong>Notinio Financial Data</strong> ©{moment().format("yyyy")} -{" "}
          <small>
            Created by{" "}
            <a
              rel="noreferrer noopener"
              href="https://deliostech.com"
              target="_blank"
            >
              Delios Technology Inc
            </a>
          </small>
          <br />
          <strong>
            <small>Version 1.0.0</small>
          </strong>
        </C.Layout.Footer>
      </C.Layout>
    </C.Layout>
  );
};

export default Layout;
