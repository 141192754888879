import * as React from "react";
import * as H from "hooks";
import * as U from "utils";
import moment from "moment";
import { API } from "aws-amplify";
import { Line } from "@ant-design/plots";

const CurrencyPairDiagram = (props: any) => {
  const [state, setState] = React.useState({
    data_dayRates: [],
    data_dayRates_loading: true,
    maxQuote: 0.0,
    minQuote: 0.0,
  });

  /* 
    WeekyStartDateTime Interval has changed to 5 Days
    Task #30 https://delios.atlassian.net/browse/NWC-30
  */
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().utc().format("YYYY-MM-DD-HH-00-00");
  const yearToDateStartDate =  moment().utc().format("YYYY-01-01-00-00-00");
  const yearlyStartDate = moment().subtract(1, "y").format("YYYY-MM-DD");
  const halfyearlyStartDate = moment().subtract(6, "M").format("YYYY-MM-DD");
  const quarterlylyStartDate = moment().subtract(3, "M").format("YYYY-MM-DD");
  const monthlyStartDate = moment().subtract(1, "M").format("YYYY-MM-DD");
  const weeklyStartDateTime = moment()
    .subtract(5, "d")
    .utc()
    .format("YYYY-MM-DD-HH-00-00");
  const dailyStartDateTime = moment()
    .subtract(1, "d")
    .utc()
    .format("YYYY-MM-DD-HH-00-00");
  const { baseCurrencyIsoAlpha3, quoteCurrencyIsoAlpha3 } = H.useParams();

  React.useEffect(() => {
    switch (props.radioValue) {
      case "Daily":
        // console.log("a", "Daily");

        if (
          baseCurrencyIsoAlpha3 &&
          quoteCurrencyIsoAlpha3 &&
          dailyStartDateTime &&
          currentDateTime &&
          props.count === 0
        ) {
          setState((prev) => ({ ...prev, data_dayRates_loading: true }));

          API.get(
            "fx-rates",
            `/pair/historical/minute/${dailyStartDateTime}/${currentDateTime}/${baseCurrencyIsoAlpha3}/${quoteCurrencyIsoAlpha3}`,
            {}
          )
            .then((record) => {
              let maxQuote = 0.0;
              let minQuote = 10000000.0;
              for (let i = 0; i < Object.keys(record).length; i += 1) {
                if (record[i].spotPrice > maxQuote) {
                  maxQuote = Number.parseFloat(record[i].spotPrice);
                }
                if (record[i].spotPrice < minQuote) {
                  minQuote = Number.parseFloat(record[i].spotPrice);
                }
              }
              setState((prev) => ({
                ...prev,
                data_dayRates: record,
                data_dayRates_loading: true,
                maxQuote,
                minQuote,
              }));
              if (!record) {
                U.showNotification("error", "Error", "Not found");
                setState((prev) => ({
                  ...prev,
                  recordNotFound: true,
                }));
              }
            })
            .catch((e) => {
              // console.log("error", e);
              const msg =
                e?.response?.data_pair?.error ||
                e.message ||
                "Failed to get data";
              U.showNotification("error", "Error", msg);
            })
            .finally(() => {
              setState((prev) => ({ ...prev, data_dayRates_loading: false }));
            });
        }

        break;

      case "Weekly":
        // console.log("b", "Weekly");

        if (
          baseCurrencyIsoAlpha3 &&
          quoteCurrencyIsoAlpha3 &&
          weeklyStartDateTime &&
          currentDateTime &&
          props.count === 0
        ) {
          setState((prev) => ({ ...prev, data_dayRates_loading: true }));

          API.get(
            "fx-rates",
            `/pair/historical/hour/${weeklyStartDateTime}/${currentDateTime}/${baseCurrencyIsoAlpha3}/${quoteCurrencyIsoAlpha3}`,
            {}
          )
            .then((record) => {
              let maxQuote = 0.0;
              let minQuote = 10000000.0;
              for (let i = 0; i < Object.keys(record).length; i += 1) {
                if (record[i].spotPrice > maxQuote) {
                  maxQuote = Number.parseFloat(record[i].spotPrice);
                }
                if (record[i].spotPrice < minQuote) {
                  minQuote = Number.parseFloat(record[i].spotPrice);
                }
              }
              setState((prev) => ({
                ...prev,
                data_dayRates: record,
                data_dayRates_loading: true,
                maxQuote,
                minQuote,
              }));
              if (!record) {
                U.showNotification("error", "Error", "Not found");
                setState((prev) => ({
                  ...prev,
                  recordNotFound: true,
                }));
              }
            })
            .catch((e) => {
              // console.log("error", e);
              const msg =
                e?.response?.data_pair?.error ||
                e.message ||
                "Failed to get data";
              U.showNotification("error", "Error", msg);
            })
            .finally(() => {
              setState((prev) => ({ ...prev, data_dayRates_loading: false }));
            });
        }

        break;

      case "Monthly":
        // console.log("c", "Monthly");

        if (
          baseCurrencyIsoAlpha3 &&
          quoteCurrencyIsoAlpha3 &&
          yearlyStartDate &&
          currentDate &&
          props.count === 0
        ) {
          setState((prev) => ({ ...prev, data_dayRates_loading: true }));

          API.get(
            "fx-rates",
            `/pair/historical/day/${monthlyStartDate}/${currentDate}/${baseCurrencyIsoAlpha3}/${quoteCurrencyIsoAlpha3}`,
            {}
          )
            .then((record) => {
              let maxQuote = 0.0;
              let minQuote = 10000000.0;
              for (let i = 0; i < Object.keys(record).length; i += 1) {
                if (record[i].spotPrice > maxQuote) {
                  maxQuote = Number.parseFloat(record[i].spotPrice);
                }
                if (record[i].spotPrice < minQuote) {
                  minQuote = Number.parseFloat(record[i].spotPrice);
                }
              }
              setState((prev) => ({
                ...prev,
                data_dayRates: record,
                data_dayRates_loading: true,
                maxQuote,
                minQuote,
              }));
              if (!record) {
                U.showNotification("error", "Error", "Not found");
                setState((prev) => ({
                  ...prev,
                  recordNotFound: true,
                }));
              }
            })
            .catch((e) => {
              const msg =
                e?.response?.data_pair?.error ||
                e.message ||
                "Failed to get data";
              U.showNotification("error", "Error", msg);
            })
            .finally(() => {
              setState((prev) => ({ ...prev, data_dayRates_loading: false }));
            });
        }

        break;

      case "Quarterly":
        // console.log("d", "Quarterly");

        if (
          baseCurrencyIsoAlpha3 &&
          quoteCurrencyIsoAlpha3 &&
          yearlyStartDate &&
          currentDate &&
          props.count === 0
        ) {
          setState((prev) => ({ ...prev, data_dayRates_loading: true }));

          API.get(
            "fx-rates",
            `/pair/historical/day/${quarterlylyStartDate}/${currentDate}/${baseCurrencyIsoAlpha3}/${quoteCurrencyIsoAlpha3}`,
            {}
          )
            .then((record) => {
              let maxQuote = 0.0;
              let minQuote = 10000000.0;
              for (let i = 0; i < Object.keys(record).length; i += 1) {
                if (record[i].spotPrice > maxQuote) {
                  maxQuote = Number.parseFloat(record[i].spotPrice);
                }
                if (record[i].spotPrice < minQuote) {
                  minQuote = Number.parseFloat(record[i].spotPrice);
                }
              }
              setState((prev) => ({
                ...prev,
                data_dayRates: record,
                data_dayRates_loading: true,
                maxQuote,
                minQuote,
              }));
              if (!record) {
                U.showNotification("error", "Error", "Not found");
                setState((prev) => ({
                  ...prev,
                  recordNotFound: true,
                }));
              }
            })
            .catch((e) => {
              const msg =
                e?.response?.data_pair?.error ||
                e.message ||
                "Failed to get data";
              U.showNotification("error", "Error", msg);
            })
            .finally(() => {
              setState((prev) => ({ ...prev, data_dayRates_loading: false }));
            });
        }

        break;

      case "HalfYearly":
        // console.log("e", "HalfYearly");

        if (
          baseCurrencyIsoAlpha3 &&
          quoteCurrencyIsoAlpha3 &&
          yearlyStartDate &&
          currentDate &&
          props.count === 0
        ) {
          setState((prev) => ({ ...prev, data_dayRates_loading: true }));

          API.get(
            "fx-rates",
            `/pair/historical/day/${halfyearlyStartDate}/${currentDate}/${baseCurrencyIsoAlpha3}/${quoteCurrencyIsoAlpha3}`,
            {}
          )
            .then((record) => {
              let maxQuote = 0.0;
              let minQuote = 10000000.0;
              for (let i = 0; i < Object.keys(record).length; i += 1) {
                if (record[i].spotPrice > maxQuote) {
                  maxQuote = Number.parseFloat(record[i].spotPrice);
                }
                if (record[i].spotPrice < minQuote) {
                  minQuote = Number.parseFloat(record[i].spotPrice);
                }
              }
              setState((prev) => ({
                ...prev,
                data_dayRates: record,
                data_dayRates_loading: true,
                maxQuote,
                minQuote,
              }));
              if (!record) {
                U.showNotification("error", "Error", "Not found");
                setState((prev) => ({
                  ...prev,
                  recordNotFound: true,
                }));
              }
            })
            .catch((e) => {
              const msg =
                e?.response?.data_pair?.error ||
                e.message ||
                "Failed to get data";
              U.showNotification("error", "Error", msg);
            })
            .finally(() => {
              setState((prev) => ({ ...prev, data_dayRates_loading: false }));
            });
        }

        break;

      case "YearToDate":
        // console.log("f", "YearToDate");

        if (
          baseCurrencyIsoAlpha3 &&
          quoteCurrencyIsoAlpha3 &&
          yearlyStartDate &&
          currentDate &&
          props.count === 0
        ) {
          setState((prev) => ({ ...prev, data_dayRates_loading: true }));

          API.get(
            "fx-rates",
            `/pair/historical/day/${yearToDateStartDate}/${currentDate}/${baseCurrencyIsoAlpha3}/${quoteCurrencyIsoAlpha3}`,
            {}
          )
            .then((record) => {
              let maxQuote = 0.0;
              let minQuote = 10000000.0;
              for (let i = 0; i < Object.keys(record).length; i += 1) {
                if (record[i].spotPrice > maxQuote) {
                  maxQuote = Number.parseFloat(record[i].spotPrice);
                }
                if (record[i].spotPrice < minQuote) {
                  minQuote = Number.parseFloat(record[i].spotPrice);
                }
              }
              setState((prev) => ({
                ...prev,
                data_dayRates: record,
                data_dayRates_loading: true,
                maxQuote,
                minQuote,
              }));
              if (!record) {
                U.showNotification("error", "Error", "Not found");
                setState((prev) => ({
                  ...prev,
                  recordNotFound: true,
                }));
              }
            })
            .catch((e) => {
              const msg =
                e?.response?.data_pair?.error ||
                e.message ||
                "Failed to get data";
              U.showNotification("error", "Error", msg);
            })
            .finally(() => {
              setState((prev) => ({ ...prev, data_dayRates_loading: false }));
            });
        }

        break;

      case "Yearly":
        // console.log("g", "Yearly");

        if (
          baseCurrencyIsoAlpha3 &&
          quoteCurrencyIsoAlpha3 &&
          yearlyStartDate &&
          currentDate &&
          props.count === 0
        ) {
          setState((prev) => ({ ...prev, data_dayRates_loading: true }));

          API.get(
            "fx-rates",
            `/pair/historical/day/${yearlyStartDate}/${currentDate}/${baseCurrencyIsoAlpha3}/${quoteCurrencyIsoAlpha3}`,
            {}
          )
            .then((record) => {
              let maxQuote = 0.0;
              let minQuote = 10000000.0;
              for (let i = 0; i < Object.keys(record).length; i += 1) {
                if (record[i].spotPrice > maxQuote) {
                  maxQuote = Number.parseFloat(record[i].spotPrice);
                }
                if (record[i].spotPrice < minQuote) {
                  minQuote = Number.parseFloat(record[i].spotPrice);
                }
              }
              setState((prev) => ({
                ...prev,
                data_dayRates: record,
                data_dayRates_loading: true,
                maxQuote,
                minQuote,
              }));
              if (!record) {
                U.showNotification("error", "Error", "Not found");
                setState((prev) => ({
                  ...prev,
                  recordNotFound: true,
                }));
              }
            })
            .catch((e) => {
              const msg =
                e?.response?.data_pair?.error ||
                e.message ||
                "Failed to get data";
              U.showNotification("error", "Error", msg);
            })
            .finally(() => {
              setState((prev) => ({ ...prev, data_dayRates_loading: false }));
            });
        }

        break;

      default:
        // console.log("a", "Daily");
        break;
    }
  }, [
    baseCurrencyIsoAlpha3,
    quoteCurrencyIsoAlpha3,
    currentDate,
    currentDateTime,
    yearToDateStartDate,
    yearlyStartDate,
    halfyearlyStartDate,
    quarterlylyStartDate,
    monthlyStartDate,
    weeklyStartDateTime,
    dailyStartDateTime,
    props,
  ]);

  const { data_dayRates } = state;

  const dayRatesChartConfig = {
    autoFit: true,
    data: data_dayRates,
    height: 400,
    xField: "timestamp",
    yField: "spotPrice",
    seriesField: "category",
    meta: {
      spotPrice: {
        max: state.maxQuote,
        min: state.minQuote,
      },
      timestamp: {
        type: "time",
      },
    },
    animation: {
      appear: {
        animation: "path-in",
        duration: 3000,
      },
    },
    smooth: true,
  };

  if (state.data_dayRates.length < 1) {
    return null;
  }

  return (
    <div>
      <Line {...dayRatesChartConfig} />
    </div>
  );
};

export default CurrencyPairDiagram;
