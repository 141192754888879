import * as React from "react";
import { Select, SelectProps, FormItemProps, Form } from "antd";
import { useIntl } from "hooks";
import { Types } from "./duck";

const filterOption: SelectProps<Record<string, any>>["filterOption"] = (
  input,
  option
) => {
  if (option) {
    return (option.children as unknown as string)
      ?.toLowerCase()
      ?.includes(input.toLowerCase());
  }

  return !!option;
};

const { Option } = Select;

const Dropdown = React.forwardRef(
  (
    {
      options = [],
      placeholder,
      showArrow = true,
      allowClear = false,
      getOptionProps,
      style = { width: "100%" },
      ...props
    }: Types.InnerSelectProps,
    ref
  ) => {
    return (
      <Select
        // eslint-disable-next-line
        // @ts-ignore
        ref={ref}
        autoComplete="new-password"
        style={style}
        placeholder={placeholder}
        allowClear={allowClear}
        showArrow={showArrow}
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        {...props}
      >
        {options.map((option) => {
          const { value, children, ...rest } = getOptionProps(option);

          return (
            <Option key={value} value={value} {...rest}>
              {children}
            </Option>
          );
        })}
      </Select>
    );
  }
);

export default React.forwardRef(
  (
    {
      label,
      name,
      isFormItem = true,
      preserve = true,
      required,
      rules = [],
      ...props
    }: Types.WrapperProps & FormItemProps,
    ref
  ) => {
    const intl = useIntl();

    const S = <Dropdown {...props} ref={ref} />;
    if (!isFormItem) {
      return S;
    }
    return (
      <Form.Item
        label={intl.formatMessage({ id: label, defaultMessage: "" })}
        name={name}
        preserve={preserve}
        rules={[
          {
            required,
            message: intl.formatMessage({ id: "common.required" }),
          },
          ...rules,
        ]}
      >
        {S}
      </Form.Item>
    );
  }
);
