import { configureStore } from "@reduxjs/toolkit";
import { appStateReducer } from "./slices";

export const store = configureStore({
  reducer: {
    appState: appStateReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["AUTHENTICATE_USER/fulfilled", "LOGIN/fulfilled"],
        ignoredPaths: ["appState.loggedInUser"],
      },
    });
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
