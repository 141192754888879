import * as C from "components";
import moment from "moment";
import { API } from "aws-amplify";

const OpenTestApiInComponent = () => {
  API.get("accounts", "", {})
    .then((record) => {
      // eslint-disable-next-line
      console.info(record);
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error(error);
    })
    .finally(() => {
      // eslint-disable-next-line
      console.info("API call completed");
    });

  return (
    <div
      style={{
        padding: 24,
        background: "#fff",
        minHeight: 360,
      }}
    >
      <C.PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: "1px solid rgb(235, 237, 240)",
          marginBottom: 16,
        }}
        title="Open - Test API in Component"
      />
      <br />
      Notinio Financial Data ©{moment().format("yyyy")}
    </div>
  );
};

export default OpenTestApiInComponent;
