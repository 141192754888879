import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Types from "./types";
import { Auth } from "aws-amplify";
import { showNotification } from "utils";

export const authenticateUser = createAsyncThunk<Types.CognitoUser>(
  "AUTHENTICATE_USER",
  () => Auth.currentAuthenticatedUser({ bypassCache: false })
);

export const login = createAsyncThunk<
  Types.CognitoUser,
  { email: string; password: string }
>("LOGIN", ({ email, password }) =>
  Auth.signIn(email.toLowerCase(), password).catch((e) => {
    showNotification("error", "Error", e.message);
    return Promise.reject();
  })
);

export const logOut = createAsyncThunk("LOGOUD", () => Auth.signOut());
