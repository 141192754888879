/* eslint-disable */

import * as React from "react";

import { Divider, Form, message, Modal } from "antd";

import { InputText } from "components";

import { Auth } from "aws-amplify";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

interface ComponentProps {
  email: string;
  visible: boolean;
  onCancel: () => void;
}

const ResetPasswordModal: React.FC<ComponentProps> = ({
  email,
  visible,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const onOk = async () => {
    const { verificationCode, password } = form.getFieldsValue();

    setLoading(true);

    try {
      await Auth.forgotPasswordSubmit(
        email.toLowerCase(),
        verificationCode,
        password
      );

      message.info(
        "Password has been successfully set.  You may now login using the new password.",
        10
      );

      onCancel();
    } catch (e) {
      message.warning("Invalid Verification Code - please retry", 10);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Password Reset"
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={loading}
      okText="Submit"
    >
      <p>
        Please check your email (including spam folder) for a verification code.
        <br />
        Enter it below, with your chosen new password.
        <br />
        <br />
        <strong>
          New password must be 8 characters including uppercase, lowercase one
          number and one symbol.
        </strong>
      </p>
      <Divider />
      <Form {...formItemLayout} form={form}>
        <InputText required name="verificationCode" label="Verification Code" />
        <InputText
          required
          label="Password"
          name="password"
          type="Password"
          dependencies={["confirmPassword"]}
          rules={[
            {
              validator: (_: any, value: string) => {
                const reg =
                  // eslint-disable-next-line
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/;
                if ((!reg.test(value) || /&/g.test(value)) && value) {
                  return Promise.reject(
                    "8 characters long including uppercase, lowercase, a number and a symbol = + - ^ $ . [ ] { } ( ) ! @ # % _ ~"
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        />
        <InputText
          required
          label="Confirm Password"
          name="confirmPassword"
          type="Password"
          preserve={false}
          dependencies={["password"]}
          rules={[
            ({
              getFieldValue,
            }: {
              getFieldValue: (field: string) => string;
            }) => ({
              validator: (rule: any, value: string) => {
                const password = getFieldValue("password");
                if (!value || !password || password === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords do not match");
              },
            }),
          ]}
        />
      </Form>
    </Modal>
  );
};

export default ResetPasswordModal;
