import * as C from "components";
import moment from "moment";

const Open01 = () => (
  <div
    style={{
      padding: 24,
      background: "#fff",
      minHeight: 360,
    }}
  >
    <C.PageHeader
      style={{
        paddingLeft: 0,
        borderBottom: "1px solid rgb(235, 237, 240)",
        marginBottom: 16,
      }}
      title="Notinio Financial Data"
    />
    <strong>This is a test page - Open 01</strong>
    <br />
    Notinio Financial Data ©{moment().format("yyyy")}
  </div>
);

export default Open01;
