import * as React from "react";

import logoDefault from "assets/img/notinio-200x50.png";
import favIconDefault from "assets/img/notinio-logo.png";

import classNames from "classnames";

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({ logo: { minHeight: 40 } });

interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  const classes = useStyles();

  React.useEffect(() => {
    const el = document.getElementById("favicon") as HTMLLinkElement | null;

    if (el) {
      el.href = favIconDefault;
    }
  }, []);

  return (
    <img
      alt="Page Header Logo"
      src={logoDefault}
      className={classNames(classes.logo, className)}
    />
  );
};

export default Logo;
