import { dashboardHOC } from "hocs";
import { createElement } from "react";
import {
  Login,
  Open01,
  Open02,
  OpenTestListHOC,
  OpenTestApiInComponent,
  PremiumTest,
} from "../components";

import { CurrenciesRatesMajor } from "../../market/currencies/rates/major";
import { CurrencyPair } from "../../market/currencies/pair";

export const SECURED_ROUTES = [
  {
    path: "/dashboard",
    Element: dashboardHOC({
      title: "Dashboard",
      subTitle: "Welcome to Notinio Financial Data",
      Component: () => createElement("div", null, "tmp"),
    }),
  },
];

export const OPEN_ROUTES = [
  {
    path: "/login",
    Element: Login,
  },
  {
    path: "/open-01",
    Element: Open01,
  },
  {
    path: "/open-02",
    Element: Open02,
  },
  {
    path: "/open/test-listHOC",
    Element: OpenTestListHOC,
  },
  {
    path: "/open/test-api-in-component",
    Element: OpenTestApiInComponent,
  },
  {
    path: "/open/markets/currencies/major/:baseCurrencyIsoAlpha3",
    Element: CurrenciesRatesMajor,
  },
  {
    path: "/open/markets/currencies/pair/:baseCurrencyIsoAlpha3/:quoteCurrencyIsoAlpha3",
    Element: CurrencyPair,
  },
  {
    path: "/premium/test",
    Element: PremiumTest,
  },
];
