import * as React from "react";

import * as H from "hooks";

import { Auth } from "aws-amplify";

import { createUseStyles } from "react-jss";

import { Row, Form, Card, Button, message, Spin } from "antd";

import { InputEmail, InputText } from "components";

import { ResetPasswordModal } from "./components";

import { appStateAsyncActions } from "configs";

import { FormattedMessage } from "react-intl";

const warning = (text: string) => {
  message.warning(text, 10);
};

const info = (text: string) => {
  message.info(text, 10);
};

const useStyles = createUseStyles({
  fullWidth: { width: "100%" },
  space: { justifyContent: "space-between" },
  btnSubmit: { marginBottom: 24 },
  card: { maxWidth: 600 },
  form: { marginTop: 20 },
  wrapper: {
    minHeight: 360,
    backgroundColor: "#fff",
    padding: 24,
  },
});

const LoginPageComponent = () => {
  const classes = useStyles();
  const dispatch = H.useDispatch();
  const navigate = H.useNavigate();
  const intl = H.useIntl();
  const [form] = Form.useForm<{ email: string; password: string }>();

  const { loading, isAuthenticated } = H.useSelector((state) => ({
    loading: state.appState.loading,
    isAuthenticated: !!state.appState.loggedInUser,
  }));

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const [modalVisible, setModalVisible] = React.useState(false);

  const onForgotPasswordClick = () => {
    const email = form.getFieldValue("email") || "";

    Auth.forgotPassword(email.toLowerCase())
      .then((data) => {
        info(
          `Please check your email ${data.CodeDeliveryDetails.Destination} for a verification number`
        );

        setModalVisible(true);
      })
      .catch((e) => {
        if (e.message === "Username cannot be empty") {
          warning("You must enter an email address");
        }
      });
  };

  const onSubmit = () => {
    const { email, password } = form.getFieldsValue();
    dispatch(
      appStateAsyncActions.login({
        email,
        password,
      })
    );
  };

  return (
    <div className={classes.wrapper}>
      <Spin spinning={loading}>
        <Row justify="center">
          <Card
            title={<FormattedMessage id="button.login" />}
            className={classes.card}
          >
            <Form form={form} onFinish={onSubmit} className={classes.form}>
              <InputEmail
                required
                label=""
                inputProps={{
                  placeholder: intl.formatMessage({ id: "common.email" }),
                }}
              />
              <InputText
                required
                type="Password"
                name="password"
                placeholder={intl.formatMessage({ id: "common.password" })}
              />
              <Button
                htmlType="submit"
                type="primary"
                className={`${classes.fullWidth} ${classes.btnSubmit}`}
              >
                <FormattedMessage id="button.login" />
              </Button>
              <Button
                onClick={onForgotPasswordClick}
                className={classes.fullWidth}
              >
                <FormattedMessage id="loginPage.forgotPassword" />
              </Button>
            </Form>
          </Card>
        </Row>
        <ResetPasswordModal
          email={form.getFieldValue("email")}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
        />
      </Spin>
    </div>
  );
};

export default LoginPageComponent;
