import * as React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, DatePicker, TableColumnType } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";
import { InputSelect } from "components";
import { Types } from "./duck";

const { RangePicker } = DatePicker;

const inputTypes: Types.INPUT_TYPES = {
  DATE_RANGE: "DATE_RANGE",
  INPUT: "INPUT",
  SELECT: "SELECT",
};

type FetchCb = () => Promise<any>;

interface ListHoc {
  <R>(Component: any, fetchCb?: FetchCb): React.FC<R>;
  inputTypes: Types.INPUT_TYPES;
}

const listHOC: ListHoc = (Component, fetchCb) => (props: any) => {
  const [{ searchWords, rangeValues, searchedColumn }, setState] =
    React.useState<Types.ListHocState>({
      searchWords: [],
      rangeValues: null,
      searchedColumn: "",
    });

  const [entitiesState, setEntitiesState] = React.useState({
    entities: [],
    loading: false,
  });

  const searchInput = React.useRef<{
    focus: () => void;
    select: () => void;
    blur: () => void;
  }>();

  React.useEffect(() => {
    if (fetchCb) {
      setEntitiesState((prev) => ({
        ...prev,
        loading: true,
      }));
      fetchCb()
        .then((resp) => {
          setEntitiesState((prev) => ({
            ...prev,
            entities: resp,
          }));
        })
        .finally(() => {
          setEntitiesState((prev) => ({
            ...prev,
            loading: false,
          }));
        });
    }
  }, []);

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: string
  ) => {
    confirm({ closeDropdown: true });
    setState({
      searchedColumn: dataIndex,
      searchWords: selectedKeys,
      rangeValues,
    });
  };

  const handleReset = (clearFilters?: () => void) => {
    if (clearFilters) {
      clearFilters();
    }

    setState({
      searchedColumn: "",
      searchWords: [],
      rangeValues: null,
    });
  };

  const getColumnSearchProps: Types.GetColumnSearchProps = ({
    dataIndex,
    placeholder = dataIndex,
    filterInputType = inputTypes.INPUT,
    render = (highlighter) => highlighter,
    selectFilterOptions = [],
    getOptionProps = (option) => ({
      children: option,
      value: option,
    }),
  }) => {
    let onFilter: TableColumnType<any>["onFilter"];
    let getFilterComponent: (
      props: Omit<FilterDropdownProps, "prefixCls" | "visible">
    ) => React.ReactNode;

    switch (filterInputType) {
      case inputTypes.DATE_RANGE:
        onFilter = (value, record) => {
          const [from, to] = searchWords;
          return record[dataIndex] >= from && record[dataIndex] <= to;
        };
        getFilterComponent = ({ setSelectedKeys }) => (
          <div style={{ marginBottom: 5 }}>
            <RangePicker
              showTime
              value={rangeValues}
              ref={(ref: any) => {
                searchInput.current = ref;
              }}
              onChange={(momentValues: any, stringValues: any) => {
                setSelectedKeys(stringValues);

                setState({
                  searchedColumn,
                  searchWords: stringValues,
                  rangeValues: momentValues,
                });
              }}
            />
          </div>
        );
        break;
      case inputTypes.SELECT:
        onFilter = (value, record) => record[dataIndex] === value;
        getFilterComponent = ({ setSelectedKeys, selectedKeys }) => (
          <div style={{ marginBottom: 5 }}>
            <InputSelect
              allowClear={false}
              showSearch={false}
              isFormItem={false}
              placeholder={`Filter by ${placeholder}`}
              options={Array.from(
                new Set(selectFilterOptions.filter((o) => o)).values()
              )}
              getOptionProps={getOptionProps}
              onChange={(value) => setSelectedKeys(value && [value])}
              value={selectedKeys[0]}
              ref={(ref: any) => {
                searchInput.current = ref;
              }}
            />
          </div>
        );
        break;
      case inputTypes.INPUT:
        onFilter = (value, record) =>
          String(record[dataIndex])
            .toLowerCase()
            .includes(String(value).toLowerCase());

        getFilterComponent = ({ setSelectedKeys, selectedKeys, confirm }) => (
          <Input
            ref={(ref: any) => {
              searchInput.current = ref;
            }}
            placeholder={`Search by ${placeholder}`}
            value={selectedKeys[0]}
            onChange={({ target: { value } }) => setSelectedKeys([value])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              width: 188,
              marginBottom: 8,
              display: "block",
            }}
          />
        );
        break;
      default:
        break;
    }

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          {getFilterComponent({
            setSelectedKeys,
            selectedKeys,
            confirm,
          })}
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              marginRight: 8,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => {
        const style = filtered ? { color: "#1890ff" } : {};
        return <SearchOutlined {...style} />;
      },
      onFilter,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          const focusMethods: Record<
            keyof typeof inputTypes,
            "focus" | "select"
          > = {
            [inputTypes.DATE_RANGE]: "focus",
            [inputTypes.INPUT]: "select",
            [inputTypes.SELECT]: "focus",
          };

          setTimeout(() => {
            const method = focusMethods[filterInputType];

            if (searchInput.current && searchInput.current[method]) {
              searchInput.current[method]();
            }
          }, 100);
        }
      },
      render: (text, record) =>
        render(
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{
                backgroundColor: "#ffc069",
                padding: 0,
              }}
              searchWords={searchWords.map((el) => String(el))}
              autoEscape
              textToHighlight={(text || "").toString()}
            />
          ) : (
            text
          ),
          record
        ),
    };
  };

  return (
    <Component
      {...props}
      {...entitiesState}
      getColumnSearchProps={getColumnSearchProps}
    />
  );
};

listHOC.inputTypes = inputTypes;

export default listHOC;
