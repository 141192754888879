import { notification } from "antd";

type IconType = "success" | "info" | "error" | "warning";
type OpenNotification = (
  type: IconType,
  header: string,
  message: string
) => void;

const showNotificationWithIcon: OpenNotification = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

export default showNotificationWithIcon;
