import * as React from "react";
import * as H from "hooks";
import * as U from "utils";
import * as C from "components";
import moment from "moment";
import { API } from "aws-amplify";
import { Skeleton } from "components";
import { Row, Col, Typography, Card, Radio, Progress } from "antd";
import type { RadioChangeEvent } from "antd";
import CurrencyPairDiagram from "./CurrencyPairDiagram";

const { Text } = Typography;

const optionsWithDisabled = [
  { label: "YTD", value: "YearToDate" },
  { label: "1Y", value: "Yearly" },
  { label: "6M", value: "HalfYearly" },
  { label: "3M", value: "Quarterly" },
  { label: "1M", value: "Monthly" },
  { label: "5D", value: "Weekly" },
  { label: "1D", value: "Daily" },
];

const CurrencyPair = () => {
  const [state, setState] = React.useState({
    data_pair: [],
    data_dayRates: [],
    spotPriceLive: 0,
    data_pair_open_diff: { spotPrice: 0 },
    data_pair_open_previous_day: { spotPrice: 0 },
    pair_loading: true,
    data_dayRates_loading: true,
    maxQuote: 0.0,
    minQuote: 0.0,
  });
  const { baseCurrencyIsoAlpha3, quoteCurrencyIsoAlpha3 } = H.useParams();
  const [radioValue, setRadioValue] = React.useState("Yearly");
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    let id: any = 0;
    if (count === 30) {
      id = setInterval(
        () =>
          setCount((oldCount: any) => {
            let tempOldCount = oldCount;
            tempOldCount = 0;
            return tempOldCount;
          }),
        1000
      );

      if (baseCurrencyIsoAlpha3 && quoteCurrencyIsoAlpha3) {
        setState((prev) => ({ ...prev, pair_loading: true }));

        API.get(
          "fx-rates",
          `/pair/current/${baseCurrencyIsoAlpha3}/${quoteCurrencyIsoAlpha3}`,
          {}
        )
          .then((record) => {
            setState((prev) => ({
              ...prev,
              spotPriceLive: record.spotPriceLive,
              data_pair: record.current,
              data_pair_open_diff: record.openDiff,
              data_pair_open_previous_day: record.open,
              pair_loading: true,
            }));
            if (!record) {
              U.showNotification("error", "Error", "Not found");
              setState((prev) => ({
                ...prev,
                recordNotFound: true,
              }));
            }
          })
          .catch((e) => {
            const msg =
              e?.response?.data_pair?.error ||
              e.message ||
              "Failed to get data";
            U.showNotification("error", "Error", msg);
          })
          .finally(() => {
            setState((prev) => ({ ...prev, pair_loading: false }));
          });
      }
    } else {
      id = setInterval(() => setCount((oldCount: any) => oldCount + 1), 1000);
    }
    return () => {
      clearInterval(id);
    };
  }, [count, baseCurrencyIsoAlpha3, quoteCurrencyIsoAlpha3]);

  React.useEffect(() => {
    if (baseCurrencyIsoAlpha3 && quoteCurrencyIsoAlpha3) {
      setState((prev) => ({ ...prev, pair_loading: true }));

      API.get(
        "fx-rates",
        `/pair/current/${baseCurrencyIsoAlpha3}/${quoteCurrencyIsoAlpha3}`,
        {}
      )
        .then((record) => {
          setState((prev) => ({
            ...prev,
            spotPriceLive: record.spotPriceLive,
            data_pair: record.current,
            data_pair_open_diff: record.openDiff,
            data_pair_open_previous_day: record.open,
            pair_loading: true,
          }));
          if (!record) {
            U.showNotification("error", "Error", "Not found");
            setState((prev) => ({
              ...prev,
              recordNotFound: true,
            }));
          }
        })
        .catch((e) => {
          const msg =
            e?.response?.data_pair?.error || e.message || "Failed to get data";
          U.showNotification("error", "Error", msg);
        })
        .finally(() => {
          setState((prev) => ({ ...prev, pair_loading: false }));
        });
    }
  }, [baseCurrencyIsoAlpha3, quoteCurrencyIsoAlpha3]);

  const onRadioChange = ({ target: { value } }: RadioChangeEvent) => {
    setRadioValue(value);
    setCount(0);
  };

  const getDiagramTitle = () => {
    switch (radioValue) {
      case "YearToDate":
        return "YTD";
      case "Yearly":
        return "1Y";
      case "HalfYearly":
        return "6M";
      case "Quarterly":
        return "3M";
      case "Monthly":
        return "1M";
      case "Weekly":
        return "5D";
      case "Daily":
        return "1D";
      default:
        return "";
    }
  };

  const getOpenDiff = (spotPrice: any, openPrice: any) => {
    const result: any = spotPrice.toFixed(5) - openPrice.toFixed(5);
    if (result > 0) {
      return <Text type="success">{result.toFixed(5)}</Text>;
    }
    return <Text type="danger">{result.toFixed(5)}</Text>;
  };

  const getPrevious = (openPrice: any) => {
    const result: any = openPrice;
    return <Text>{result.toFixed(5)}</Text>;
  };

  return (
    <div
      style={{
        padding: 24,
        background: "#fff",
        minHeight: 360,
      }}
    >
      <C.PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: "1px solid rgb(235, 237, 240)",
          marginBottom: 16,
        }}
        title="Open - Test OpenMarketsCurrencies Pair API in Component"
      />
      <Radio.Group
        options={optionsWithDisabled}
        onChange={onRadioChange}
        value={radioValue}
        optionType="button"
        buttonStyle="solid"
      />
      <Skeleton loading={state.pair_loading}>
        <C.Table
          loading={state.pair_loading}
          dataSource={[state.data_pair]}
          columns={[
            {
              title: "Currency Pair",
              dataIndex: "isoAlpha3",
              render: (text: any, record: any) => {
                return (
                  <div>
                    <Row gutter={[24, 0]}>
                      <Col xs={6}>
                        <img
                          src={`https://wise.com/public-resources/assets/flags/rectangle/${record.isoAlpha3.toLowerCase()}.png`}
                          alt=""
                          width="36px"
                          height="24px"
                        />{" "}
                        <img
                          src={`https://wise.com/public-resources/assets/flags/rectangle/${record.quotecurrency_isoAlpha3.toLowerCase()}.png`}
                          alt=""
                          width="36px"
                          height="24px"
                        />
                      </Col>
                      <Col xs={6}>
                        <a
                          href={`/open/markets/currencies/pair/${record.isoAlpha3}/${record.quotecurrency_isoAlpha3}`}
                        >{`${record.isoAlpha3}${record.quotecurrency_isoAlpha3}`}</a>
                        <br />
                        {`${record.fullName}`} /{" "}
                        {`${record.quotecurrency_fullName}`}
                        <br />
                        Current Price: {`${record.spotPrice}`}
                        <br />
                        Current as at:
                        {`${moment(record.timestamp)
                          .utc(true)
                          .format("DD-MM-YYYY HH:mm")}`}
                        <br />
                      </Col>
                      <Col xs={12}>
                        <Progress
                          showInfo={false}
                          percent={(count / 30) * 100}
                          size="small"
                          steps={30}
                        />
                        {` Rate refresh in ${30 - count} secs`}
                        <br />
                        <br />
                        Previous Day Open:{" "}
                        {getPrevious(
                          state.data_pair_open_previous_day.spotPrice
                        )}
                        <br />
                        Today Open:{" "}
                        {`${
                          state.data_pair_open_diff?.spotPrice
                            ? state.data_pair_open_diff.spotPrice
                            : ""
                        }`}
                        <br />
                        Today Change:{" "}
                        {getOpenDiff(
                          record.spotPrice,
                          state.data_pair_open_diff.spotPrice
                        )}
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </div>
                );
              },
            },
          ]}
          pagination={false}
        />
      </Skeleton>
      <Card
        title={
          <Typography.Title level={4}>{getDiagramTitle()}</Typography.Title>
        }
      >
        <CurrencyPairDiagram radioValue={radioValue} count={count} />
      </Card>
      Notinio Financial Data ©{moment().format("yyyy")}
    </div>
  );
};

export default CurrencyPair;
