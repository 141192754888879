import * as React from "react";
import * as C from "components";
import { createUseStyles } from "react-jss";

interface Topics {
  pageTitle?: string;
  pageSubTitle?: string;
  pageExtra?: any;
}

export interface ChildProps {
  getTopics(args: { title?: string; subTitle?: string; extra?: any }): void;
}

interface DashboardHOC<P> {
  Component: React.FC<P>;
  title?: string;
  subTitle?: string;
}

const useStyles = createUseStyles({
  header: {
    borderBottom: "1px solid rgb(235, 237, 240)",
    marginBottom: 16,
  },
  card: { flexGrow: 2 },
});

export const dashboardHOC =
  <P extends ChildProps>({ Component, title, subTitle }: DashboardHOC<P>) =>
  (props: any) => {
    const [topics, setTopics] = React.useState<Topics>({
      pageTitle: title,
      pageSubTitle: subTitle,
      pageExtra: [],
    });

    const classes = useStyles();

    const getTopics: ChildProps["getTopics"] = (newTopics) => {
      setTopics({
        pageTitle: newTopics.title || topics.pageTitle,
        pageSubTitle: newTopics.subTitle || topics.pageSubTitle,
        pageExtra: newTopics.extra || topics.pageExtra,
      });
    };

    return (
      <C.PageHeader
        className={classes.header}
        title={topics.pageTitle}
        extra={topics.pageExtra}
      >
        {topics.pageSubTitle && (
          <C.Typography.Title level={5}>
            {topics.pageSubTitle}
          </C.Typography.Title>
        )}
        <Component {...props} getTopics={getTopics} />
      </C.PageHeader>
    );
  };
