import React from "react";

import {
  DashboardOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import Logo from "../logo";

import { createUseStyles } from "react-jss";
import { appStateAsyncActions } from "configs";

import { Row, Col, Button, Layout } from "antd";

import * as H from "hooks";

const useStyles = createUseStyles({
  header: {
    padding: "0 24px",
    background: "#fff",
    height: "auto",
    borderBottom: "1px solid #f0f0f0",
  },
  logo: { width: 200 },
});

interface HeaderProps {
  withLogo?: boolean;
}

const Header: React.FC<HeaderProps> = ({ withLogo }) => {
  const classes = useStyles();
  const isAuthenticated = H.useSelector(
    (state) => !!state.appState.loggedInUser
  );

  const dispatch = H.useDispatch();
  const navigate = H.useNavigate();

  const handleDashboard = () => {
    navigate("/dashboard");
  };

  const handleUser = () => {
    navigate("/user-profile");
  };

  const handleLogout = () => {
    dispatch(appStateAsyncActions.logOut());
  };

  return (
    <Layout.Header className={classes.header}>
      <Row justify="space-between">
        <Col>{withLogo && <Logo className={classes.logo} />}</Col>
        {isAuthenticated && (
          <Col>
            <Button
              type="primary"
              onClick={handleDashboard}
              icon={<DashboardOutlined />}
            />
            <Button
              type="primary"
              onClick={handleUser}
              icon={<UserOutlined />}
            />
            <Button
              type="primary"
              onClick={handleLogout}
              icon={<LogoutOutlined />}
            />
          </Col>
        )}
      </Row>
    </Layout.Header>
  );
};

export default Header;
