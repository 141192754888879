import * as React from "react";

import { Form, FormItemProps, Input, InputProps } from "antd";

interface InputEmailProps extends FormItemProps {
  required?: boolean;
  isDuplicate?: boolean;
  inputProps?: InputProps;
}

const InputEmail: React.FC<InputEmailProps> = ({
  required,
  label = "E-mail",
  name = "email",
  isDuplicate,
  inputProps = {},
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required,
          message: `${label || "This field"} is required`,
        },
        {
          type: "email",
          message: "Email is invalid",
        },
        {
          validator() {
            return isDuplicate
              ? Promise.reject("Email already exists")
              : Promise.resolve();
          },
        },
      ]}
    >
      <Input {...inputProps} />
    </Form.Item>
  );
};
export default InputEmail;
