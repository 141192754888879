import * as React from "react";
import * as H from "hooks";
import * as U from "utils";
import * as C from "components";
import moment from "moment";
import { API } from "aws-amplify";
import { Skeleton } from "components";
import { Row, Col } from "antd";

const CurrenciesRatesMajor = () => {
  const [state, setState] = React.useState({
    data: [],
    loading: true,
  });
  const { baseCurrencyIsoAlpha3 } = H.useParams();

  React.useEffect(() => {
    if (baseCurrencyIsoAlpha3) {
      setState((prev) => ({ ...prev, loading: true }));

      API.get("fx-rates", `/majors/current/${baseCurrencyIsoAlpha3}`, {})
        .then((record) => {
          setState((prev) => ({
              ...prev,
              data: record
          }));
          if (!record) {
            U.showNotification("error", "Error", "Not found");
            setState((prev) => ({
              ...prev,
              recordNotFound: true,
            }));
          }
        })
        .catch((e) => {
          const msg =
            e?.response?.data?.error || e.message || "Failed to get data";
          U.showNotification("error", "Error", msg);
        })
        .finally(() => {
          setState((prev) => ({ ...prev, loading: false }));
        });
    }
  }, [baseCurrencyIsoAlpha3]);

  return (
    <div
      style={{
        padding: 24,
        background: "#fff",
        minHeight: 360,
      }}
    >
      <C.PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: "1px solid rgb(235, 237, 240)",
          marginBottom: 16,
        }}
        title="Open - Test OpenMarketsCurrencies API in Component"
      />
      <Skeleton loading={state.loading}>
      <C.Table
        loading={state.loading}
        dataSource={state.data}
        size="small"
        columns={[
          {
            title: "Currency Pair",
            dataIndex: "isoAlpha3",
            render: (text: any, record: any) => {
               return <div>
                <Row gutter={[24,0]}>
                  <Col xs={6}>
                    <img src={`https://wise.com/public-resources/assets/flags/rectangle/${record.isoAlpha3.toLowerCase()}.png`} alt='' width="36px" height="24px"/>
                    {" "}<img src={`https://wise.com/public-resources/assets/flags/rectangle/${record.quotecurrency_isoAlpha3.toLowerCase()}.png`} alt='' width="36px" height="24px"/>
                  </Col>
                  <Col xs={18}>
                    <a href={`/open/markets/currencies/pair/${record.isoAlpha3}/${record.quotecurrency_isoAlpha3}`}>{`${record.isoAlpha3}${record.quotecurrency_isoAlpha3}`}</a><br/>
                    {`${record.fullName}`} / {`${record.quotecurrency_fullName}`}<br/>
                    {' '}{`${record.spotPrice}`}<br/>
                    {' '}{`${moment(record.timestamp).utc(false).format('DD-MM-YYYY HH:mm')}`}<br/>
                  </Col>
                </Row>
              </div>
            }
          },
        ]}
      />
      </Skeleton>
      Notinio Financial Data ©{moment().format("yyyy")}
    </div>
  );
};

export default CurrenciesRatesMajor;
