import Amplify from "aws-amplify";

const config = {
  // Backend config
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
};

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    Storage: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
    API: {
      endpoints: [
        {
          name: "accounts",
          endpoint: `${config.apiGateway.URL}/accounts`,
          region: config.apiGateway.REGION,
        },
        {
          name: "fx-rates",
          endpoint: `${config.apiGateway.URL}/fx-rates`,
          region: config.apiGateway.REGION,
        },
        {
          name: "staff",
          endpoint: `${config.apiGateway.URL}/staff`,
          region: config.apiGateway.REGION,
        },
        {
          name: "clients",
          endpoint: `${config.apiGateway.URL}/clients`,
          region: config.apiGateway.REGION,
        },
        {
          name: "applications",
          endpoint: `${config.apiGateway.URL}/applications`,
          region: config.apiGateway.REGION,
        },
        {
          name: "countries",
          endpoint: `${config.apiGateway.URL}/countries`,
          region: config.apiGateway.REGION,
        },
        {
          name: "streetTypes",
          endpoint: `${config.apiGateway.URL}/street-types`,
          region: config.apiGateway.REGION,
        },
        // {
        //   name: "applications",
        //   endpoint:
        //     "https://wotmc3acrb.execute-api.us-east-1.amazonaws.com/dev/applications",
        //   region: creds.apiGateway.REGION,
        // },
        // {
        //   name: "email",
        //   endpoint:
        //     "https://uv29ot7w30.execute-api.us-east-1.amazonaws.com/dev/email",
        //   region: creds.apiGateway.REGION,
        // },
        // {
        //   name: "logging",
        //   endpoint:
        //     "https://7dsvbojb4h.execute-api.us-east-1.amazonaws.com/dev/logging",
        //   region: creds.apiGateway.REGION,
        // },
        // {
        //   name: "staff",
        //   endpoint:
        //     "https://j005wiu8z2.execute-api.us-east-1.amazonaws.com/dev/staff",
        //   region: creds.apiGateway.REGION,
        // },
      ],
    },
  });
};
