import * as React from "react";
import { IntlProvider } from "react-intl";
import * as C from "components";
import { Layout } from "features";
import { appStateAsyncActions, configureAmplify } from "configs";
import { useSelector, useDispatch } from "hooks";
import { createUseStyles } from "react-jss";
import flatten from "flat";
import { en, zh } from "./translations";
// import { Auth } from "aws-amplify";

const messages = { en, zh };

configureAmplify();

const useStyles = createUseStyles({
  spin: {
    height: "100%",
    "& .ant-spin-container": {
      height: "100%",
    },
  },
});

const App = () => {
  const { language, loading } = useSelector((state) => state.appState);
  const dispatch = useDispatch();
  const classes = useStyles();

  React.useEffect(() => {
    dispatch(appStateAsyncActions.authenticateUser());

    // Auth.signUp('dev.001@deliostech.com', 'Passw0rd!')
    // Auth.signUp('ivanroskishny@gmail.com', 'r@R12312')
    // Auth.confirmSignUp('ivanroskishny@gmail.com', '841130')
    // Auth.confirmSignUp("dev.001@deliostech.com", "716979");
  }, [dispatch]);

  return (
    <IntlProvider locale={language} messages={flatten(messages[language])}>
      <C.Spin spinning={loading} wrapperClassName={classes.spin}>
        <C.BrowserRouter>
          <Layout />
        </C.BrowserRouter>
      </C.Spin>
    </IntlProvider>
  );
};

export default App;
