import React from "react";

import { Form, Input, FormItemProps } from "antd";

interface InputTextProps extends FormItemProps {
  type?: "Password" | "TextArea" | "Search";
  placeholder?: string;
  label?: string;
  required?: boolean;
}

const InputText: React.FC<InputTextProps> = ({
  type,
  name,
  placeholder = "",
  rules = [],
  required,
  label,
}) => {
  const I = type ? Input[type] : Input;

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required,
          message: `${label || "This field"} is required!`,
        },
        ...rules,
      ]}
    >
      <I placeholder={placeholder} autoComplete="new-password" />
    </Form.Item>
  );
};
export default InputText;
