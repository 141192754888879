import { FC } from "react";
import * as C from "components";
import moment from "moment";
import { listHOC } from "hocs";
import { API } from "aws-amplify";
import { Account } from "types";

interface CurrenciesRatesMajorProps {
  entities: Account[];
  loading: boolean;
}

const Test: FC<CurrenciesRatesMajorProps> = ({ entities, loading }) => {
  return (
    <div
      style={{
        padding: 24,
        background: "#fff",
        minHeight: 360,
      }}
    >
      <C.PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: "1px solid rgb(235, 237, 240)",
          marginBottom: 16,
        }}
        title="Open - Test Page"
      />
      <C.Table<Account>
        loading={loading}
        dataSource={entities}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
          },
          {
            title: "File by",
            dataIndex: "file_by",
          },
        ]}
      />
      <br />
      Notinio Financial Data ©{moment().format("yyyy")}
    </div>
  );
};

export default listHOC(Test, () => API.get("accounts", "", {}));
